<template>
  
  <div class="center">
    <p>
      This page does not exist. <a href="#" onclick="history.go(-1)">Go Back</a>
    </p>
  </div>
  
</template>

<script>
export default {

}
</script>

<style>

.center {
  text-align: center;
}

</style>